import { graphql, useStaticQuery } from "gatsby"

const useLatestPost = () => {
  const {
    directus: { news },
  } = useStaticQuery(graphql`
    query latestBlogLinksQiery {
      directus {
        news (sort: "-date_created", limit: 3, filter: {status: {_eq: "published"}}) {
          date_created
          headline
          id
          intro
          map
          slug
          text
          status
          image {
            id
            imageFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `)
  return news
}

export default useLatestPost
