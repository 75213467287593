import React from "react"
import { Link } from "gatsby"
import EventItems from "./EventItems"
import PostItem from "../Post/PostItem"
import useLatestBlogPost from "../../hooks/use-latest-blog-post"
import Button from "../Button/Button"
import useAllEvents from "../../hooks/use-all-events";
import EventItem from "./EventItem";

const LatestEvents = ({ title, introduction }) => {
  const latestBlogPost = useAllEvents()
  return (
    <div className="section">
      <div className="container container__tight">
        {title || introduction ? (
          <div className="intro__area">
            {title && (
              <h2>
                {title}
                <span>.</span>
              </h2>
            )}
            {introduction && <p>{introduction}</p>}
          </div>
        ) : null}

        <EventItems>
          {latestBlogPost.map((node, index) => {
            return <EventItem key={index} node={node} />
          })}
        </EventItems>
        <div className="learn__more">
          <Button text="Alle Veranstaltungen anzeigen" as={Link} to="/events" />
        </div>
      </div>
    </div>
  )
}

export default LatestEvents
