import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"
import LatestPosts from "../components/Post/LatestPosts";
import EventFeed from "../components/Feeds/EventFeed";
import LatestEvents from "../components/Event/LatestEvents";
const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Wilkommen beim Teikojutsu"
          subTitle="Lerne Teikojutsu: Selbstverteidigung für alle – ohne Vorkenntnisse. Effektive Techniken, persönliche Sicherheit."

        />
          <LatestPosts
              title="Neuigkeiten"
              introduction="In diesem Abschnitt erhältst du ausführliche Informationen und die allerneuesten Nachrichten rund um Teikojutsu"
          />
        <LatestEvents
        title={"Veranstaltungen"}
        introduction={"Hier findest du alle Veranstaltungen"}
        ></LatestEvents>
      </Layout>
    </>
  )
}

export default Index
